// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


// This Module's Components
import { Router } from '@angular/router';
import { TemplesComponent } from './temples.component';

@NgModule({
    imports: [
        RouterModule
    ],
    declarations: [
        TemplesComponent,
    ],
    exports: [
        TemplesComponent,
    ]
})
export class TemplesModule {

}

