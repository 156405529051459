// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// This Module's Components
import { HospitalComponent } from './hospital.component';

@NgModule({
    imports: [
        RouterModule

    ],
    declarations: [
        HospitalComponent,
    ],
    exports: [
        HospitalComponent,
    ]
})
export class HospitalModule {

}
