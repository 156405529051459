import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    constructor(private router: Router) { }
    data: Date = new Date();
    onSubmit(codeType) {
        this.router.navigate(['/inicio'], { queryParams: { tab: codeType } });
    }
}
