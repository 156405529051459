import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './../message.service';
import { Globals } from './../globals';
import { Router } from '@angular/router';
import { ClientRegister, SupportView } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Session } from 'app/entities';
import { SharedService } from './../shared.service';
import { RequestMessage } from 'app/entities';

@Injectable()
export class ClientsService {
    public session: Session;
    constructor(
        private http: HttpClient,
        private router: Router,
        private messageService: MessageService,
        private globals: Globals,
        private ss: SharedService) {
        this.ss = ss;
    }

    update(item: ClientRegister): Observable<string> {

        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.clientsService + 'clients/update', item, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('update'))
        );
    }
    create(item: ClientRegister): Observable<string> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.clientsService + 'clients/create', item, request).map((res) => {
            return res;
        });
    }

    validateEmail(item: string): Observable<string> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get<string>(this.globals.clientsService + 'clients/GetMail?email=' + item, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('error'))
        );
    }
    sendContact(data: SupportView) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.clientsService + 'clients/SendMessageContact', data, request).map((res) => {
            return res;
        });
    }
    login(user): Observable<any> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post(this.globals.clientsService + 'clients/Login', user).map((res) => {
            return res;
        });
    }
    getCurrent(id): Observable<ClientRegister> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        if (id != '') {
            return this.http.get<ClientRegister>(this.globals.clientsService + 'clients/Details?id=' + id, request).map((res) => {
                return res;
            });
        }
        else {
            return this.http.get<ClientRegister>(this.globals.clientsService + 'clients/Details', request).map((res) => {
                return res;
            });
        }
    }
    async   getImage(id) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<string>(this.globals.clientsService + 'pictures/getimage?idstr=' + id, request).map((res) => {
            return res;
        }).toPromise().then(data => data);
    }
    private notifyClient(data) {
        this.ss.onMainEvent.emit(data);
    }
    recovey(data) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<string>(this.globals.clientsService + 'clients/recovery?email=' + data, request).map((res) => {
            return res;
        });
    }
    createCertificate(): Observable<any> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get(this.globals.clientsService + 'clients/certificate', request).map((res) => {
            return res;
        });
    }
    async   createMailing(data:RequestMessage) {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.clientsService + 'clients/sendMailing',data, request).map((res) => {
            return res;
        }).toPromise();
    }
}