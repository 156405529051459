import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';

declare var swal: any;


@Injectable()
export class MessageService {
  messages: string[] = [];
  errorMessages: string[] = [];


  add(message: string) {
    this.messages.push(message);
    swal({
      type: 'success',
      title: '!Guardado!',
      text: message,
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-success'
    })
  }
  addError(message: string) {
    this.errorMessages.push(message);
    swal({
      type: 'error',
      title: '!Error!',
      text: message,
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-success'
    })
  }
  clear() {
    this.messages = [];
  }
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      this.addError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
  public showTryError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.error(result);
      this.addError(error);
      return of(result as T);
    };
  }
  public showError(error) {
    
      console.error(error); // log to console instead
      this.addError(error);
  
    
  }
  public deleteEntity(service: any, id: string): void {
    swal({
      title: '¿Esta seguro que desea eliminar este elemento?',
      text: 'Despues de eliminarse el elemento no podrá volver a recuperarlo',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false
    }).then(function (response) {
      if (response.value) {
        service.delete(id).subscribe();
        return true;
      }
      else {
        return false;
      }
    });
  }
}
