// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// This Module's Components
import { SuggestedComponent } from './suggested.component';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
    ],
    declarations: [
        SuggestedComponent,
    ],
    exports: [
        SuggestedComponent,
    ]
})
export class SuggestedModule {

}