import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { SupportView } from 'app/entities';
import { ErrorStateMatcher } from '@angular/material/core';
import { Artist } from 'app/entities';
import { FormGroupService } from './../../../shared.service';
import { MatStepper } from '@angular/material';
import { MessageService } from './../../../message.service';
import { ClientsService } from './../../../clients/clients.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  contactFormGroup: FormGroup;
  support: SupportView;
  constructor(private _formBuilder: FormBuilder, private messageService: MessageService, private service: ClientsService, ) { }

  ngOnInit() {
    this.support = {
      email: '',
      isRobot: false,
      lastName: '',
      message: '',
      name: ''
    }

    this.contactFormGroup = this._formBuilder.group({
      name: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
      message: new FormControl('', [Validators.required]),
      isRobot: new FormControl('', [Validators.required]),
    });
  }
  onSubmit() {
    if (this.contactFormGroup.valid) {
      if (this.support.isRobot == false) {
        this.messageService.showError('Selecione las opcion de no robot')
      }
      else {
        this.service.sendContact(this.support).subscribe(
          data => {
            this.messageService.add(data);
          },
          error => {
            this.messageService.addError(error.error);
          });
      }
    }
  }
}