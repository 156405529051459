// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// This Module's Components
import { Router } from '@angular/router';
import { HistoryComponent } from './history.component';

@NgModule({
    imports: [
        RouterModule
    ],
    declarations: [
        HistoryComponent,
    ],
    exports: [
        HistoryComponent,
    ]
})
export class HistoryModule {

}
