// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// This Module's Components
import { MallComponent } from './mall.component';

@NgModule({
    imports: [
        RouterModule

    ],
    declarations: [
        MallComponent,
    ],
    exports: [
        MallComponent,
    ]
})
export class MallModule {

}
