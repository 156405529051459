// Angular Imports
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts/ng2-charts';
// This Module's Components
import { StatisticsComponent } from './statistics.component';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        ChartsModule,
        CommonModule,
        BrowserModule
    ],
    declarations: [
        StatisticsComponent,
    ],
    exports: [
        StatisticsComponent,
    ]
})
export class StatisticsModule {

}
