import { Component } from '@angular/core';
import { ChartsModule } from 'ng2-charts/ng2-charts';
@Component({
    moduleId: module.id,
    selector: 'suggested',
    templateUrl: 'suggested.component.html',
    styleUrls: ['suggested.component.scss']
})
export class SuggestedComponent {

    data : Date = new Date();

    constructor() { }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('profile');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-success');
        navbar.classList.add('bg-primary');
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-success');
        navbar.classList.remove('bg-primary');
    }
}
    
