import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { NewsService } from './../news/news.service';
import { ArtistService } from './../artist/artist.service';
import { OngService } from './../ong/ong.service';
//import { TravelAgencyService } from './../travelAgency/travelAgency.service';
import { TourismAgentsService } from './../services/tourismAgents.service';
import { HotelsService } from './../hotels/hotels.service';

import { News } from 'app/entities';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';


@Pipe({ name: 'getImage' })
export class GetImagePipe implements PipeTransform {
    constructor(private newsService: NewsService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.newsService.getImage(id);
        var y = this._sanitizer.bypassSecurityTrustStyle('url(' + img + ')');
        return y;
    }
}
@Pipe({ name: 'getImageStr' })
export class GetImageStrPipe implements PipeTransform {
    constructor(private newsService: NewsService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.newsService.getImage(id);
        return img;
    }
}
@Pipe({ name: 'getImageArtist' })
export class GetImageArtistPipe implements PipeTransform {
    constructor(private service: ArtistService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.service.getImage(id);
        return img;
    }
}
@Pipe({ name: 'getImageOng' })
export class GetImageOngPipe implements PipeTransform {
    constructor(private service: OngService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.service.getImage(id);
        var y = this._sanitizer.bypassSecurityTrustStyle('url(' + img + ')');
        return y;
    }
}
@Pipe({ name: 'getImageHotel' })
export class GetImageHotelPipe implements PipeTransform {
    constructor(private service: HotelsService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.service.getImage(id);
        return img;
    }
}
@Pipe({ name: 'getImageRestaurant' })
export class GetImageRestaurantPipe implements PipeTransform {
    constructor(private service: TourismAgentsService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.service.getImage(id);
        return img;
    }
}
@Pipe({ name: 'getImageAgencyTravels' })
export class GetImageAgencyTravelsPipe implements PipeTransform {
    constructor(private service: TourismAgentsService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.service.getImage(id);
        return img;
    }
}
@Pipe({ name: 'getImageTourGuide' })
export class GetImageTourGuidePipe implements PipeTransform {
    constructor(private service: TourismAgentsService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }
    async  transform(id: string) {
        var img = await  this.service.getImage(id);
        return img;
    }
}
