import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './../message.service';
import { Globals } from './../globals';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { DataTable, SelectIdView } from 'app/entities';
import { News, NewsView, ObjectView } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map'
import { Session } from './../entities';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
@Injectable()
export class NewsService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private globals: Globals) { }
    getById(id): Observable<News> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<News>(this.globals.clientsService + 'news/get/' + id, request).map((res) => {
            return res;
        })
    }
    getViewById(id): Observable<NewsView> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<NewsView>(this.globals.clientsService + 'news/getviewid/' + id, request).map((res) => {
            return res;
        })
    }
    get(page) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<DataTable>(this.globals.clientsService + 'news/get?page=' + page, request).map((res) => {
            return res;
        }).toPromise();
    }
    async   getImage(id) {
        if (id != null && id != "") {
            const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
            return this.http.get<string>(this.globals.clientsService + 'pictures/getimage?idstr=' + id, request).map((res) => {
                return res;
            }).toPromise().then(data => data);
        }
    }
    getView(): Observable<News[]> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<News[]>(this.globals.clientsService + 'news/getview', request).map((res) => {
            return res;
        });
    }
    getlast() {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<News[]>(this.globals.clientsService + 'news/GetLast', request).map((res) => {
            return res;
        });
    }

    create(news: News): Observable<string> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.clientsService + 'news/create', news, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('create'))
        );
    }
    update(news: News): Observable<string> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.put<string>(this.globals.clientsService + 'news/update/' + news.id, news, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('update'))
        );
    }
    delete(id): Observable<string> {

        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.delete<string>(this.globals.clientsService + 'news/delete/' + id, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('delete'))
        );
    }
}
