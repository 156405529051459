// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// This Module's Components
import { BankComponent } from './bank.component';

@NgModule({
    imports: [
        RouterModule

    ],
    declarations: [
        BankComponent,
    ],
    exports: [
        BankComponent,
    ]
})
export class BankModule {

}
