import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {  ClientsService } from './clients.service';
import {  ClientRegister } from './../entities';


@Injectable()
export class ClientsResolver implements Resolve<any> {
  constructor(private ms: ClientsService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    // return this.ms.getCurrent().then(data => {
    //   if (data) {
    //     return data;
    //   } else {
    //     this.router.navigate(['']);
        return;
    //  }
    //});
  }
}
