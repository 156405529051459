import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { NewsService } from '../news.service';
import { News } from 'app/entities';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, AfterViewChecked {

    data: Date = new Date();
    public news: News[];
    constructor(private service: NewsService, router: Router,
        private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }

    ngOnInit() {
       this.get();
    }
    ngOnDestroy() {
    }
    ngAfterViewChecked() {

    }
    private get() {
        this.service.getlast().subscribe(data => {
            this.news = data;
        });
    }
    // this return is working
    // return '<somebase base64 string>'
    //var y=this.newsService.getImage(url);

}

