import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit, OnDestroy {
    data : Date = new Date();
    tagItems = ['Minimal', 'Light', 'New', 'Friends'];
    categories = ['Food', 'Drink'];

  constructor() { }
  ngOnInit() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('profile');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
    navbar.classList.add('bg-info');
}
ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('profile');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
    navbar.classList.remove('bg-info');
}
}