import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './../message.service';
import { Globals } from './../globals';
import { DataTable, CommonsEntities } from 'app/entities';
import { News,NewsView } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map'
@Injectable()
export class TourismAgentsService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private globals: Globals) { }
    getById(id): Observable<News> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<News>(this.globals.clientsService + 'restaurantss/get/' + id, request).map((res) => {
            return res;
        })
    }
    getViewById(id): Observable<NewsView> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<NewsView>(this.globals.clientsService + 'restaurants/getviewid/' + id, request).map((res) => {
            return res;
        })
    }
    get(page, codeType){
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get<DataTable>(this.globals.clientsService + 'tourismAgents/get?page='+page+'&&codeType='+codeType, request).map((res) => {
            return res;
        }).toPromise();
    }
    async   getImage(id) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<string>(this.globals.clientsService + 'pictures/getimage?idstr='+id, request).map((res) => {
            return res;
        }).toPromise().then(data => data);
    }
    getView(codeType): Observable<CommonsEntities[]> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<CommonsEntities[]>(this.globals.clientsService + 'tourismAgents/getview?codeType='+codeType, request).map((res) => {
            return res;
        });
    }
    delete(id): Observable<string> {
        
        const request = { headers: new HttpHeaders().set('Authorization',JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.delete<string>(this.globals.clientsService + 'clients/delete/'+id+'/2', request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('delete'))
        );
    }
    getReport(codeType,startdate,endDate): Observable<any> {
        const request = { headers: new HttpHeaders().set('Authorization',JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get<any>(this.globals.clientsService + 'tourismAgents/report?codeType='+codeType+'&startDate=' + startdate + '&endDate=' + endDate, request).map((res) => {
            return res;
        });
    }
}
