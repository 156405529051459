import {Component, Injectable,Input,Output,EventEmitter} from '@angular/core'
import { FormGroup } from '@angular/forms';

@Injectable()
export class SharedService {
    onMainEvent: EventEmitter<any> = new EventEmitter();
} 
@Injectable()
export class FormGroupService {
    notify: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();  
} 