import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './../message.service';
import { SharedService } from './../shared.service';
import { Globals } from './../globals';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { DataTable, SelectIdView } from 'app/entities';
import { Session, ObjectView } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map'

@Injectable()
export class UsersService {
    public session: Session;
    constructor(
        private http: HttpClient,
        private router: Router,
        private messageService: MessageService,
        private globals: Globals,
        private ss: SharedService) {
        this.ss = ss;
    }
    login(user): Observable<any> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post(this.globals.clientsService + 'Users/Login', user).map((res) => {
            return res;
        });
    }
    public getCurrent(data) {
        var date = new Date();
        date.setHours(date.getDay() + 24);
        this.session = {
            expiration: date,
            tokken: data,
        };
        localStorage.removeItem('session');
        localStorage.removeItem('user');
        localStorage.removeItem('client');
        localStorage.setItem('session', JSON.stringify(this.session));
        this.changeName();
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken) };
        this.http.get(this.globals.clientsService + 'Users/getcurrent', request)
            .subscribe(data => this.goToMain(data),
                error => { this.messageService.addError(error.error); });
    }
    private goToMain(data) {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken) };
        localStorage.removeItem('user');
        localStorage.removeItem('client');
        localStorage.setItem('user', JSON.stringify(data));
        this.router.navigate(['/noticias/tabla']);
    }
    private changeName() {
        this.ss.onMainEvent.emit(1);
    }
}
