import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ReportsService } from './../../reports/reports.service';
import { Statistics, Report } from 'app/entities';
import { Chart } from 'chart.js';
import { ViewChild, Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
@Component({
  selector: 'statistics',
  templateUrl: 'statistics.component.html',
  styleUrls: ['statistics.component.scss']
})
export class StatisticsComponent {
  @ViewChild('turismo') turismoCanvas: ElementRef;
  @ViewChild('cultura') culturaCanvas: ElementRef;

  tempChart: any;
  result: Statistics;
  constructor(private service: ReportsService) { }
  ngOnInit() {
    this.getValues();
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('profile');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-primary');
    navbar.classList.add('bg-primary');
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('profile');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-primary');
    navbar.classList.remove('bg-primary');
  }
  private getValues() {
    this.service.rptStatistics('','').subscribe(data => {
      this.result = data;
      this.tempChart = this.createCanvas([this.result.artist, this.result.ong], this.turismoCanvas, 'Cantidad', ['Artistas', 'Ong']);
      this.tempChart = this.createCanvas([this.result.hotels, this.result.restaurants, this.result.travelAgency,this.result.tourGuide], this.culturaCanvas, 'Cantidad', ['Hoteles', 'Restaurantes', 'Agencias de viajes','Guias de turismo']);
    });
  }
private backgroundColor() {
  return 'rgba('
     + Math.round(Math.random() *255) + ','
     + Math.round(Math.random() *255) + ','
     + Math.round(Math.random() *255) + ','
     + '1)';
}
  private createCanvas(array, control, title, labels) {
    var ctx = control.nativeElement.getContext('2d');
    var options = {
      scales: {
        xAxes: [{
          gridLines: {
            offsetGridLines: true
          }
        }]
      }
    }
    var test = this.backgroundColor();
    var data = {
      labels: labels,
      datasets: [
        {
          fill: false,
          label: title,
          yAxisID: title,
          borderColor: this.backgroundColor(),
          pointBoarderColor: "rgba(24, 120, 240, 1)",
          backgroundColor: this.backgroundColor(),
          pointHoverBackgroundColor: "rgba(24, 120, 240, 1)",
          pointHoverBorderColor: "rgba(24, 120, 240, 1)",
          data: array
        }
      ]
    }
    var basicOption = {
      title: {
        display: true,
        text: 'Cantidad de registros',
        fontSize: 36
      },
      scales: {
        yAxes: [{
          id: title,
          type: 'linear',
          scaleLabel: {
            labelString: title,
            display: true
          },
          ticks: {
            min: 0,
          },
        }],
        xAxes: [{
          gridLines: {
            offsetGridLines: true
          }
        }]
      }
    }
    return new Chart(ctx, {
      type: 'bar',
      data: data,
      options: basicOption
    });
  }
}