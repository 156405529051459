import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'Hospital',
    templateUrl: 'hospital.component.html',
    styleUrls: ['hospital.component.scss']
})
export class HospitalComponent {
    constructor(router: Router) {

}
}

