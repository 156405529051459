import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from './../shared.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private ss: SharedService) {
        this.ss = ss;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('session')) {
            if (new Date(JSON.parse(localStorage.getItem('session')).expiration) < new Date()) {
                this.ss.onMainEvent.emit(0);
                localStorage.removeItem('session');
                localStorage.removeItem('user');
                localStorage.removeItem('client');
                this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
                return false;
            }
            // logged in so return true
            return true;
        }
        localStorage.removeItem('session');
        localStorage.removeItem('user');
        localStorage.removeItem('client');
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
@Injectable()
export class AuthCliGuard implements CanActivate {

    constructor(private router: Router, private ss: SharedService) {
        this.ss = ss;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('session')) {
            if (new Date(JSON.parse(localStorage.getItem('session')).expiration) < new Date()) {
                this.ss.onMainEvent.emit(0);
                localStorage.removeItem('session');
                localStorage.removeItem('user');
                localStorage.removeItem('client');
                this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
                return false;
            }
            // logged in so return true
            return true;
        }
        localStorage.removeItem('session');
        localStorage.removeItem('user');
        localStorage.removeItem('client');
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}