import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './../message.service';
import { Globals } from './../globals';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { DataTable, SelectIdView } from 'app/entities';
import { ObjectView, Statistics } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map'
import { Session } from './../entities';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ArtistService } from '../artist/artist.service';
import { HotelsService } from '../hotels/hotels.service';
import { ClientsService } from './../clients/clients.service';
import { TourismAgentsService } from '../services/tourismAgents.service';
import { OngService } from '../ong/ong.service';
import { DatePipe } from '@angular/common'

@Injectable()
export class ReportsService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private artistService: ArtistService,
        private hotelsService: HotelsService,
        private tourismAgentsService: TourismAgentsService,
        private ongService: OngService,
        private clientsService: ClientsService,
        public datepipe: DatePipe,
        private globals: Globals) { }
    create(type, startdate, endDate) {
        startdate=  this.datepipe.transform(startdate, 'yyyy-MM-dd');
        endDate=  this.datepipe.transform(endDate, 'yyyy-MM-dd');
        switch (type) {
            case 0: this.artistService.getReport(startdate,endDate).subscribe(data => {
                var url = this.globals.urlReportsService + '/Reports/artist';
                this.createRpt(url, data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'artistas.xlsx', true);
            });
                break;
            case 1:
                this.hotelsService.getReport(startdate,endDate).subscribe(data => {
                    var url = this.globals.urlReportsService + '/Reports/hotel';
                    this.createRpt(url, data, 'application/vnd.ms-excel', 'hoteles.xlsx', true);
                });
                break;
            case 2:
                this.tourismAgentsService.getReport(2,startdate,endDate).subscribe(data => {
                    var url = this.globals.urlReportsService + '/Reports/commons';
                    this.createRpt(url, data, 'application/vnd.ms-excel', 'restaurantes.xlsx', true);
                });
                break;
            case 3:
                this.ongService.getReport(startdate,endDate).subscribe(data => {
                    var url = this.globals.urlReportsService + '/Reports/ong';
                    this.createRpt(url, data, 'application/vnd.ms-excel', 'ongs.xlsx', true);
                });
                break;
            case 4:
                this.tourismAgentsService.getReport(4,startdate,endDate).subscribe(data => {
                    var url = this.globals.urlReportsService + '/Reports/commons';
                    this.createRpt(url, data, 'application/vnd.ms-excel', 'agenciasdeviajes.xlsx', true);
                });
                break;
            case 5:
                this.tourismAgentsService.getReport(5,startdate,endDate).subscribe(data => {
                    var url = this.globals.urlReportsService + '/Reports/commons';
                    this.createRpt(url, data, 'application/vnd.ms-excel', 'restaurantes.xlsx', true);
                });
                break;
            case 6:
                this.tourismAgentsService.getReport(6,startdate,endDate).subscribe(data => {
                    var url = this.globals.urlReportsService + '/Reports/commons';
                    this.createRpt(url, data, 'application/vnd.ms-excel', 'guiasdeturismo.xlsx', true);
                });
                break;
            case 99:
                this.rptStatistics(startdate,endDate).subscribe(data => {
                    var url = this.globals.urlReportsService + '/Reports/general';
                    this.createRpt(url, data, 'application/vnd.ms-excel', 'general.xlsx', true);
                });
                break;
        }
    }
    createCertificate() {
        this.clientsService.createCertificate().subscribe(data => {
            var url = this.globals.urlReportsService + '/Reports/certificate';
            this.createRpt(url, data, 'application/pdf', 'certificado.pdf', true);
            return true;
        });
    }
    rptStatistics(startdate,endDate): Observable<Statistics> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<Statistics>(this.globals.clientsService + 'Reports/Get?startDate=' + startdate + '&endDate=' + endDate, request).map((res) => {
            return res;
        });
    }
    private createRpt(url, data, type, file, isDownload) {
        // for (var data in datas) {
        // Create the Xhr request object
        let xhr = new XMLHttpRequest();
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('responseType', 'blob') };
        xhr.open('POST', url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Authorization', JSON.parse(localStorage.getItem('session')).tokken);
        xhr.setRequestHeader('Content-Type', 'application/json')
        // Xhr callback when we get a result back
        // We are not using arrow function because we need the 'this' context
        xhr.onreadystatechange = function () {
            // If we get an HTTP status OK (200), save the file using fileSaver
            if (xhr.readyState === 4 && xhr.status === 200) {
                var blob = new Blob([this.response], { type: type });
                var fileURL = URL.createObjectURL(blob);
                if (isDownload == false) {
                    window.open(fileURL);
                }
                else {
                    var link = document.createElement('a');
                    link.href = fileURL;
                    link.download = file;
                    link.click();
                }
            }
        };
        // Start the Ajax request
        xhr.send(JSON.stringify(data));
        //}
    }

}
