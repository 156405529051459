import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { ComponentsComponent } from './components/components.component';
//presentation
import { SectionsComponent } from './presentation/sections/sections.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { TemplesComponent } from './presentation/gallery/temples/temples.component';
import { ContemporaneasComponent } from './presentation/gallery/contemporaneas/contemporaneas.component';
import { HistoryComponent } from './presentation/gallery/history/history.component';
import { MuseumComponent } from './presentation/gallery/museum/museum.component';
import { ArquitecturaComponent } from './presentation/gallery/arquitectura/arquitectura.component';
import { SitiosnaturalesComponent } from './presentation/gallery/sitiosnaturales/sitiosnaturales.component';
import { StatisticsComponent } from './presentation/statistics/statistics.component';
import { TripsComponent } from './presentation/trips/trips.component';
import { CulturalagendaComponent } from './presentation/culturalagenda/culturalagenda.component';
import { DirectoryComponent } from './presentation/directory/directory.component';
import { HospitalComponent } from './presentation/hospital/hospital.component';
import { MallComponent } from './presentation/mall/mall.component';
import { ExchangeComponent } from './presentation/exchange/exchange.component';
import { BankComponent } from './presentation/bank/bank.component';
import { CommerceComponent } from './presentation/gallery/commerce/commerce.component';
import { TermAndConditionsComponent } from './presentation/termandconditions/termandconditions.component';
import { SuggestedComponent } from './presentation/suggested/suggested.component';
//admin
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard,AuthCliGuard } from './_guards/index';

export const AppRoutes: Routes = [
    { path: 'inicio', component: SectionsComponent,  },
    { path: '', redirectTo: 'inicio', pathMatch: 'full', },
    //presentation
    { path: 'components', component: ComponentsComponent },
    { path: 'galeria/ArquitecturaReligiosa', component: TemplesComponent },
    { path: 'galeria/RealizacionesContemporaneas', component: ContemporaneasComponent },
    { path: 'galeria/SitiosHistoricos', component: HistoryComponent },
    { path: 'galeria/ReddeMuseo', component: MuseumComponent },
    { path: 'galeria/ArquitecturaCivil', component: ArquitecturaComponent },
    { path: 'galeria/SitiosNaturales', component: SitiosnaturalesComponent },
    { path: 'galeria/PortadasyHeraldica', component: CommerceComponent },
    { path: 'estadisticas', component: StatisticsComponent },
    { path: 'condicionesdeuso', component: TermAndConditionsComponent },
    { path: 'lugaressugeridos', component: SuggestedComponent },
    { path: 'planeesuviaje', component: TripsComponent },
    { path: 'agendacultural', component: CulturalagendaComponent },
    { path: 'directorio', component: DirectoryComponent },
    { path: 'hospitales', component: HospitalComponent },
    { path: 'centrocomercial', component: MallComponent },
    { path: 'casasdecambio', component: ExchangeComponent },
    { path: 'Cajeros', component: BankComponent },

    //admin
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', loadChildren: 'app/news/newsAdmin.module#NewsAdminModule' },
            { path: 'noticias', loadChildren: 'app/news/newsAdmin.module#NewsAdminModule' },
            { path: 'artistas', loadChildren: 'app/artist/artistAdmin.module#ArtistAdminModule' },
            { path: 'agentesdeturismo', loadChildren: 'app/tourismAgents/tourismAgents.module#TourismAgentsModule' },
            { path: 'ong', loadChildren: 'app/ong/ongAdmin.module#OngAdminModule' },
            { path: 'hoteles', loadChildren: 'app/hotels/hotelsAdmin.module#HotelsAdminModule' },
            { path: 'mailing', loadChildren: 'app/mailing/mailing.module#MailingModule' },
            { path: 'reportes', loadChildren: 'app/reports/reports.module#ReportsModule' }
        ]
    },
        //cli
        {
            path: '',
            resolve: {  },
            component: AdminLayoutComponent,
            canActivate: [AuthCliGuard],
            children: [
                { path: 'clientes', loadChildren: 'app/clients/clientsAdmin.module#ClientsAdminModule' }
                
            ]
        },
    {
        path: '',
        children: [
            { path: 'noticias', loadChildren: 'app/news/news.module#NewsModule' },
            { path: 'hoteles', loadChildren: 'app/hotels/hotels.module#HotelsModule' },
            { path: 'ong', loadChildren: 'app/ong/ong.module#OngModule' },
            { path: 'malmo', loadChildren: 'app/malmo/malmo.module#MalmoModule' },
            { path: 'agentesdeturismo/restaurantes', loadChildren: 'app/tourismAgents/restaurants/restaurants.module#RestaurantsModule' },
            { path: 'agentesdeturismo/agenciasdeviajes', loadChildren: 'app/tourismAgents/travelAgency/travelAgency.module#TravelAgencyModule' },
            { path: 'agentesdeturismo/guiasdeturismo', loadChildren: 'app/tourismAgents/tourGuides/tourGuides.module#TourGuidesModule' },
            // { path: 'casasdecambios', loadChildren: 'app/tourismAgents/moneyChange/moneyChange.module#MoneyChangeModule' },
            { path: 'clientes', loadChildren: 'app/clients/clients.module#ClientsModule' },
            { path: 'usuarios', loadChildren: 'app/users/users.module#UsersModule' },
            { path: 'artistas', loadChildren: 'app/artist/artist.module#ArtistModule' },
        ]
    }
];