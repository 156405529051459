import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { SharedService } from './../../shared.service';
import { User } from 'app/entities';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    private isSession: number;
    constructor(public location: Location, private element: ElementRef, public app: Globals,
        private router: Router, private ss: SharedService) {
        this.sidebarVisible = false;
        ss.onMainEvent.subscribe(
            (isSession) => {
                this.isSession = isSession;
            }
        );
    }

    ngOnInit() {
        this.isSession = 0;
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === '/home') {
            return true;
        }
        else {
            return false;
        }
    }
    showButton(value) {
    }
    showButtonLogin() {
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '/documentation') {
            return true;
        }
        else {
            return false;
        }
    }
    onClose() {
        var user = (JSON.parse(localStorage.getItem('user')) as User);
        if (user != null) {
            localStorage.removeItem('session');
            localStorage.removeItem('user');
            localStorage.removeItem('client');
            this.router.navigate(['/usuarios/ingresar']);
        }
        else {
            localStorage.removeItem('session');
            localStorage.removeItem('user');
            localStorage.removeItem('client');
            this.router.navigate(['/clientes/ingresar']);
        }
    }
}
