import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './../message.service';
import { Globals } from './../globals';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { DataTable, SelectIdView, Hotel } from 'app/entities';
import { News, NewsView, ObjectView } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map'
import { Session } from './../entities';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
@Injectable()
export class HotelsService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private globals: Globals) { }

    getViewById(id: string): Observable<Hotel> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<Hotel>(this.globals.clientsService + 'hotels/getviewById?idstr=' + id, request).map((res) => {
            return res;
        });
    }

    get(page) {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get<DataTable>(this.globals.clientsService + 'hotels/get?page=' + page, request).map((res) => {
            return res;
        }).toPromise();
    }
    async   getImage(id) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<string>(this.globals.clientsService + 'pictures/getimage?idstr=' + id, request).map((res) => {
            return res;
        }).toPromise().then(data => data);
    }
    getView(tipo): Observable<Hotel[]> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<Hotel[]>(this.globals.clientsService + 'hotels/getview?tipo=' + tipo, request).map((res) => {
            return res;
        });
    }

    create(news: News): Observable<string> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.clientsService + 'hotels/create', news, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('create'))
        );
    }
    update(news: News): Observable<string> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.put<string>(this.globals.clientsService + 'hotels/update/' + news.id, news, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('update'))
        );
    }
    delete(id): Observable<string> {

        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.delete<string>(this.globals.clientsService + 'clients/delete/' + id + '/1', request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('delete'))
        );
    }
    getReport(startdate, endDate): Observable<any> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get<any>(this.globals.clientsService + 'hotels/report?startDate=' + startdate + '&endDate=' + endDate, request).map((res) => {
            return res;
        });
    }
}
