import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'Exchange',
    templateUrl: 'exchange.component.html',
    styleUrls: ['exchange.component.scss']
})
export class ExchangeComponent {
    constructor(router: Router) {

}
}

