import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../message.service';
import { Globals } from '../globals';
import { DataTable, SelectIdView, Ong } from 'app/entities';
import { RequestMalmo } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map'
@Injectable()
export class MalmoService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private globals: Globals) { }

    create(news: RequestMalmo): Observable<string> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.malmoApiService + 'reservation/create', news, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('create'))
        );
    }

    get(page) {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get<DataTable>(this.globals.malmoApiService + 'reservation/get?page=' + page, request).map((res) => {
            return res;
        }).toPromise();
    }

    getByCode(id): Observable<RequestMalmo> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<RequestMalmo>(this.globals.malmoApiService + 'reservation/getByCode?code=' + id, request).map((res) => {
            return res;
        })
    }

    update(news: RequestMalmo): Observable<string> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.put<string>(this.globals.malmoApiService + 'reservation/update/', news, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('update'))
        );
    }
    delete(id): Observable<string> {

        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.delete<string>(this.globals.malmoApiService + 'reservation/delete/' + id, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('delete'))
        );
    }
}