import { Component } from '@angular/core';
import { ChartsModule } from 'ng2-charts/ng2-charts';
@Component({
    moduleId: module.id,
    selector: 'termandconditions',
    templateUrl: 'termandconditions.component.html',
    styleUrls: ['termandconditions.component.scss']
})
export class TermAndConditionsComponent {

    data : Date = new Date();

    constructor() { }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('profile');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        navbar.classList.add('bg-danger');
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        navbar.classList.remove('bg-danger');
    }
}
    
