import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionsComponent } from './sections.component';
import { HeadersComponent } from './headers/headers.component';
import { AboutComponent } from './about/about.component';
import { OptionsComponent } from './options/options.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactusComponent } from './contactus/contactus.component';
import { SectionComponent } from './../../news/section/section.component';
import { GetImagePipe } from './../../pipes/image.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        SectionsComponent,
        HeadersComponent,
        AboutComponent,
        OptionsComponent,
        GalleryComponent,
        ContactusComponent,
        SectionComponent,
        GetImagePipe
    ],
    exports: [
        SectionsComponent
    ]
})
export class SectionsModule { }
