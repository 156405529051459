// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// This Module's Components
import { CommerceComponent } from './commerce.component';
import { Router } from '@angular/router';


@NgModule({
    imports: [
        RouterModule

    ],
    declarations: [
        CommerceComponent,
    ],
    exports: [
        CommerceComponent,
    ]
})
export class CommerceModule {

}
