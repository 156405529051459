import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { SectionsModule } from './presentation/sections/sections.module';
import { ComponentsModule } from './components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
//commmons
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

//presentation modules
import { TemplesModule } from './presentation/gallery/temples/temples.module';
import { ContemporaneasModule } from './presentation/gallery/contemporaneas/contemporaneas.module';
import { HistoryModule } from './presentation/gallery/history/history.module';
import { MuseumModule } from './presentation/gallery/museum/museum.module';
import { ArquitecturaModule } from './presentation/gallery/arquitectura/arquitectura.module';
import { SitiosnaturalesModule } from './presentation/gallery/sitiosnaturales/sitiosnaturales.module';
import { CommerceModule } from './presentation/gallery/commerce/commerce.module';
import { StatisticsModule } from './presentation/statistics/statistics.module';
import { TermAndConditionsModule } from './presentation/termandconditions/termandconditions.module';
import { SuggestedModule } from './presentation/suggested/suggested.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { TripsModule } from './presentation/trips/trips.module';
import { CulturalagendaModule } from './presentation/culturalagenda/culturalagenda.module';
import { DirectoryModule } from './presentation/directory/directory.module';
import { HospitalModule } from './presentation/hospital/hospital.module';
import { MallModule } from './presentation/mall/mall.module';
import { ExchangeModule } from './presentation/exchange/exchange.module';
import { BankModule } from './presentation/bank/bank.module';
import { DatePipe } from '@angular/common'
//admin
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard, AuthCliGuard } from './_guards/index';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
//services
import { MessageService } from './message.service';
import { SharedService } from './shared.service';
import { NewsService } from './news/news.service';
import { ArtistService } from './artist/artist.service';
import { HotelsService } from './hotels/hotels.service';
import { OngService } from './ong/ong.service';
import { MalmoService } from './malmo/malmo.service';
import { RateService } from './services/rate.service';
import { TourismAgentsService } from './services/tourismAgents.service';
import { UsersService } from './users/users.service';
import { ClientsService } from './clients/clients.service';
//resolver
import { ClientsResolver } from './clients/clients.resolve.service';
//global
import { Globals } from './globals';
//others
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,

} from '@angular/material';
import { AppRoutes } from './app.routing';
import swal from 'sweetalert2';
import { ReportsService } from './reports/reports.service';
@NgModule({
    exports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
    ]
})
export class MaterialModule { }

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        AdminLayoutComponent, AuthLayoutComponent,
        // AdminLayoutComponent, AuthLayoutComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule.forRoot(),
        FormsModule,
        RouterModule,
        SectionsModule,
        ComponentsModule,
        //presentation
        ContemporaneasModule,
        TemplesModule,
        HistoryModule,
        MuseumModule,
        ArquitecturaModule,
        SitiosnaturalesModule,
        CommerceModule,
        TermAndConditionsModule,
        SuggestedModule,
        TripsModule,
        CulturalagendaModule,
        StatisticsModule,
        DirectoryModule,
        HospitalModule,
        MallModule,
        ExchangeModule,
        BankModule,
        //entitie
        //others
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        HttpModule,
        MaterialModule,
        MatNativeDateModule,
        HttpClientModule,
        Ng2ImgMaxModule
    ],
    providers: [Globals, DatePipe,
        //services
        MessageService, AuthGuard, AuthCliGuard, NewsService, ArtistService, UsersService, SharedService, ClientsService, HotelsService,
        TourismAgentsService, OngService, ReportsService, MalmoService, RateService,
        //resolver
        ClientsResolver, { provide: MAT_DATE_LOCALE, useValue: 'Es-CO' },],
    bootstrap: [AppComponent]
})

export class AppModule { }