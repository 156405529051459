import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl, SafeStyle } from '@angular/platform-browser';
@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
    icon_video_button_class: string = 'fa-play';
    text_video_button: string = '';
    videoUrl:SafeResourceUrl;
  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('assets/video/cover.mp4');
  }

  playBackgroundVideo(event){
      var id_video = document.getElementById('video-source') as HTMLVideoElement;
      var parent = event.target.parentElement.parentElement;
    if(id_video.paused){
         id_video.play();
        this.text_video_button = 'Pause Video';
        this.icon_video_button_class = 'fa-pause';
     } else {
         id_video.pause();
        this.text_video_button = 'Play Video';
        this.icon_video_button_class = 'fa-play';
     }
  }

}
