// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


// This Module's Components
import { Router } from '@angular/router';
import {SitiosnaturalesComponent } from './sitiosnaturales.component';

@NgModule({
    imports: [
        RouterModule
    ],
    declarations: [
        SitiosnaturalesComponent,
    ],
    exports: [
        SitiosnaturalesComponent,
    ]
})
export class SitiosnaturalesModule {

}

