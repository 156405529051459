// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


// This Module's Components
import { ArquitecturaComponent } from './arquitectura.component';
import { Router } from '@angular/router';


@NgModule({
    imports: [
        RouterModule

    ],
    declarations: [
        ArquitecturaComponent,
    ],
    exports: [
        ArquitecturaComponent,
    ]
})
export class ArquitecturaModule {

}
