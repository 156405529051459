// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// This Module's Components
import { CulturalagendaComponent } from './culturalagenda.component';

@NgModule({
    imports: [
        RouterModule,
    ],
    declarations: [
        CulturalagendaComponent,
    ],
    exports: [
        CulturalagendaComponent,
    ]
})
export class CulturalagendaModule {

}
