import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, ObjectEntity, ClientRegister } from 'app/entities';

export class Globals {
  //clientsService = 'http://localhost:52234/api/';
  clientsService = 'https://tctservices.azurewebsites.net/api/';
  //  urlReportsService = 'http://localhost:56744/api/';
  urlReportsService = 'https://reportstpt.azurewebsites.net/api/';
  malmoApiService = 'https://malmoservices.azurewebsites.net/api/';
  //malmoApiService = 'http://localhost:52284/api/';

  public getRole(): number {
    var user = (JSON.parse(localStorage.getItem('user')) as User);
    if (user == null) {
      return 0;
    }
    return user.codeRole;
  }
  public isSession(): number {
    var user = (JSON.parse(localStorage.getItem('user')) as User);
    if (user != null) {
      return 1;
    }
    else {
      if (localStorage.getItem('client') != undefined) {
        return 2;
      }
      else {
        return 0;
      }
    }
  }
}