import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../message.service';
import { Globals } from '../globals';
import { RequestRate, DataTable } from 'app/entities';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map'

@Injectable()
export class RateService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private globals: Globals) { }

    get(id: string) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<number>(this.globals.clientsService + 'rate/rate?id=' + id, request).map((res) => {
            return res;
        })
    }

    getTable(page: number, codeType: string) {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.get<DataTable>(this.globals.clientsService + 'rate/get?page=' + page + 'codeType=' + codeType, request).map((res) => {
            return res;
        }).toPromise();
    }

    getById(id: string) {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.get<RequestRate[]>(this.globals.clientsService + 'rate/getById?id=' + id, request).map((res) => {
            return res;
        })
    }
    create(requestRate: RequestRate): Observable<string> {
        const request = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post<string>(this.globals.clientsService + 'rate/create', requestRate, request).map((res) => {
            return res;
        })
    }

    delete(id: string): Observable<string> {
        const request = { headers: new HttpHeaders().set('Authorization', JSON.parse(localStorage.getItem('session')).tokken).set('Content-Type', 'application/json') };
        return this.http.delete<string>(this.globals.clientsService + 'rate/delete/' + id, request).pipe(
            tap((data: string) => this.messageService.add(data)),
            catchError(this.messageService.handleError<string>('delete'))
        );
    }


}
